import { isEmpty, map, noop, omit } from "lodash-es";
import React, { ReactElement } from "react";
import ReactHtmlParser, {
  convertNodeToElement,
  Transform,
} from "react-html-parser";
import { DomElement } from "htmlparser2";
import ScriptTag from "react-script-tag";
import Link from "./link";

const convertChildren = (
  children: DomElement,
  index: number
): string | JSX.Element | (string | JSX.Element)[] | undefined =>
  map(children, (childNode) =>
    convertNodeToElement(childNode, index, noop() as unknown as Transform)
  );

const htmlToReact = (html: string | undefined): null | ReactElement[] => {
  if (!html) {
    return null;
  }

  return ReactHtmlParser(html, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    transform: (node, index) => {
      if (node.type === "script") {
        if (!isEmpty(node.children)) {
          return (
            <ScriptTag key={index} {...node.attribs}>
              {convertChildren(node.children, index)}
            </ScriptTag>
          );
        } else {
          return <ScriptTag key={index} {...node.attribs} />;
        }
      } else if (node.type === "tag" && node.name === "a") {
        const href = node.attribs.href;
        const props = omit(node.attribs, "href");
        // use Link only if there are no custom attributes like style, class, and what's not that might break react
        if (isEmpty(props)) {
          return (
            <Link key={index} to={href} {...props}>
              {convertChildren(node.children, index)}
            </Link>
          );
        }
      }
    },
  });
};
export default htmlToReact;
