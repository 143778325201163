import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image";
import React from "react";
import { Link, withPrefix } from "../utils";

const ImageLink = ({
  article,
  articles,
}: {
  article: GatsbyTypes.Article;
  articles: readonly GatsbyTypes.ArticleEdge[] | undefined;
}): JSX.Element | null => {
  const hasImage = article.image_blogfeed;
  let imageLink = null;

  if (hasImage) {
    const hasExternalURL = article.externalUrl;
    const img_alt = article.image_blogfeed?.alternativeText;
    const caption = article?.image_blogfeed?.caption;
    if (hasExternalURL) {
      const imageNodes = articles?.map(({ node }) => node);
      const imageId = article.image_blogfeed?.id;

      const hasGBImage = imageId
        ? getArticleFeedImage(imageNodes, imageId)
        : null;
      if (hasGBImage) {
        imageLink = (
          <a
            className="post-thumbnail"
            target="_blank"
            rel="noopener noreferrer"
            href={hasExternalURL}
          >
            <GatsbyImage
              className="thumbnail"
              image={hasGBImage}
              alt={img_alt ? img_alt : ""}
            />
            <div className="post-meta">{caption}</div>
          </a>
        );
      } else {
        return null;
      }
    } else {
      const url = article.childMarkdownRemark?.fields?.url;
      const imageNodes = articles?.map(({ node }) => node);
      const imageId = article.image_blogfeed?.id;

      const hasGBImage = imageId
        ? getArticleFeedImage(imageNodes, imageId)
        : null;
      if (hasGBImage) {
        imageLink = (
          <Link className="post-thumbnail" to={withPrefix(url)}>
            <GatsbyImage
              className="thumbnail"
              image={hasGBImage}
              alt={img_alt ? img_alt : ""}
            />
            <div className="post-meta">{caption}</div>
          </Link>
        );
      } else {
        return null;
      }
    }
  }
  return imageLink;
};

const getArticleFeedImage = (
  imageNodes: GatsbyTypes.Maybe<GatsbyTypes.Article[]>,
  imageId: GatsbyTypes.Maybe<number>
): IGatsbyImageData | undefined => {
  const image: GatsbyTypes.Maybe<GatsbyTypes.Article> = imageNodes?.find(
    (imageNode: GatsbyTypes.Article) => {
      return imageNode.image_blogfeed?.id === imageId;
    }
  );

  return getImage(image?.image_blogfeed?.localFile as unknown as ImageDataLike);
};

export default ImageLink;
