import { get, join, map, trim, unionBy } from "lodash-es";
import React from "react";
import { Helmet } from "react-helmet";
import "../sass/main.scss";

import { attribute, withPrefix } from "../utils";
import truncate from "../utils/truncate";

const Body = ({
  site,
  page,
  ...props
}: {
  site: GatsbyTypes.Maybe<GatsbyTypes.SitePageContextSite>;
  page: GatsbyTypes.SitePageContext;
  children: React.ReactNode;
}): JSX.Element => {
  const frontmatter: GatsbyTypes.Maybe<GatsbyTypes.SitePageContextFrontmatter> =
    page.frontmatter;
  const seo: GatsbyTypes.Maybe<GatsbyTypes.SitePageContextFrontmatterSeo> =
    frontmatter?.seo;
  const seoTitle: GatsbyTypes.Maybe<string> = seo?.title;
  const seoExtras: GatsbyTypes.Maybe<
    readonly GatsbyTypes.Maybe<GatsbyTypes.SitePageContextFrontmatterSeoExtra>[]
  > = seo?.extra;
  // const seoRobots = seo?.robots;
  const seoDescription: GatsbyTypes.Maybe<string> = seo?.description;
  const portfolioTitle: GatsbyTypes.Maybe<string> = site?.siteMetadata?.title;
  const pageTitle: GatsbyTypes.Maybe<string> = frontmatter?.title;
  const pageExerpt: GatsbyTypes.Maybe<string> = frontmatter?.excerpt;
  const siteDomain: GatsbyTypes.Maybe<string> = site?.siteMetadata?.domain;
  const imagePath: GatsbyTypes.Maybe<string> = frontmatter?.img_path;
  const thumbImagePath: GatsbyTypes.Maybe<string> = frontmatter?.thumb_img_path;
  const contentImagePath: GatsbyTypes.Maybe<string> =
    frontmatter?.content_img_path;
  const fallBackImagePath: GatsbyTypes.Maybe<string> =
    site?.siteMetadata?.header?.background_img;
  const palette: GatsbyTypes.Maybe<string> = site?.siteMetadata?.palette;
  // const favIcon = site?.siteMetadata?.favicon;
  const pageExerptMaxLength = 120;
  const defaultDescription = "Willkommen auf meiner Autorenwebseite";

  const defaultSEO: GatsbyTypes.SitePageContextFrontmatterSeoExtra[] = [
    {
      name: "og:type",
      keyName: "property",
      value: "article",
      id: undefined,
      relativeUrl: undefined,
    },
    {
      name: "og:title",
      keyName: "property",
      value: pageTitle,
      id: undefined,
      relativeUrl: undefined,
    },
    {
      name: "og:description",
      keyName: "property",
      value: pageExerpt
        ? truncate(pageExerpt, pageExerptMaxLength, true)
        : defaultDescription,
      id: undefined,
      relativeUrl: undefined,
    },
    {
      name: "og:image",
      keyName: "property",
      value: undefined,
      id: undefined,
      relativeUrl: true,
    },
    {
      name: "twitter:card",
      keyName: undefined,
      value: "summary_large_image",
      id: undefined,
      relativeUrl: undefined,
    },
    {
      name: "twitter:title",
      keyName: undefined,
      value: pageTitle,
      id: undefined,
      relativeUrl: undefined,
    },
    {
      name: "twitter:description",
      keyName: undefined,
      value: pageExerpt
        ? truncate(pageExerpt, pageExerptMaxLength, true)
        : defaultDescription,
      id: undefined,
      relativeUrl: undefined,
    },
    {
      name: "twitter:image",
      keyName: undefined,
      value: undefined,
      id: undefined,
      relativeUrl: true,
    },
  ];

  const unionSeo = unionBy(seoExtras, defaultSEO, "name");

  return (
    <React.Fragment>
      <Helmet htmlAttributes={{ lang: "de" }}>
        <title>
          {seoTitle
            ? seoTitle + " | " + portfolioTitle
            : pageTitle + " | " + portfolioTitle}
        </title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width" />
        <meta name="google" content="notranslate" />
        <meta name="description" content={seoDescription || ""} />
        {get(page, "frontmatter.seo.robots", null) && (
          <meta
            name="robots"
            content={join(get(page, "frontmatter.seo.robots", null), ",")}
          />
        )}
        {map(unionSeo, (meta, meta_idx) => {
          const key_name = meta?.keyName || "name";
          return meta?.relativeUrl ? (
            siteDomain &&
              ((): JSX.Element => {
                let rel_url;
                const domain = trim(siteDomain, "/");
                if (
                  (meta.name === "og:image" || meta.name === "twitter:image") &&
                  !meta.value
                ) {
                  if (imagePath) {
                    rel_url = withPrefix(imagePath);
                  } else if (thumbImagePath) {
                    rel_url = withPrefix(thumbImagePath);
                  } else if (contentImagePath) {
                    rel_url = withPrefix(contentImagePath);
                  } else {
                    rel_url = withPrefix(fallBackImagePath);
                  }
                } else {
                  rel_url = withPrefix(meta?.value);
                }
                const full_url = domain + rel_url;
                return (
                  <meta
                    key={meta_idx}
                    {...attribute(key_name, meta.name)}
                    content={full_url}
                  />
                );
              })()
          ) : (
            <meta
              key={meta_idx + ".1"}
              {...attribute(key_name, meta?.name)}
              content={meta?.value}
            />
          );
        })}
        {get(site, "siteMetadata.favicon", null) && (
          <link
            rel="icon"
            href={withPrefix(get(site, "siteMetadata.favicon", null))}
          />
        )}
        <body className={"palette-" + palette} />
      </Helmet>
      <div id="page" className="site">
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default Body;
