import { map } from "lodash-es";
import React from "react";
import { htmlToReact, Link, withPrefix } from "../utils";

const Footer = ({
  site,
}: {
  site: GatsbyTypes.Maybe<GatsbyTypes.SitePageContextSite>;
}): JSX.Element => {
  const footer: GatsbyTypes.Maybe<GatsbyTypes.SitePageContextSiteSiteMetadataFooter> =
    site?.siteMetadata?.footer;
  const content: GatsbyTypes.Maybe<string> = footer?.content;
  const links: GatsbyTypes.Maybe<
    readonly GatsbyTypes.Maybe<GatsbyTypes.SitePageContextSiteSiteMetadataFooterLinks>[]
  > = footer?.links;
  return (
    <footer id="colophon" className="site-footer inner">
      {(content || links) && (
        <div className="site-footer-inside">
          {content && <span className="copyright">{htmlToReact(content)}</span>}
          {map(links, (link, link_idx) => (
            <Link
              key={link_idx}
              to={withPrefix(link?.url)}
              {...(link?.new_window
                ? { target: "_blank", rel: "noopener" }
                : null)}
            >
              {link?.label}
            </Link>
          ))}
        </div>
      )}
    </footer>
  );
};
export default Footer;
