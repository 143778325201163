import { kebabCase } from "lodash-es";

const buildSlug = (title: string | undefined): string => {
  const slug = title ? `/${buildName(title)}` : "";
  return slug;
};

const buildName = (title: string | undefined): string => {
  return title ? `${kebabCase(title)}` : "";
};

export default buildSlug;
