import dayjs from "dayjs";
import { map, orderBy } from "lodash-es";
import React from "react";
import { Link, withPrefix } from "../utils";
import buildSlug from "../utils/slugs";
import Badge from "./Badge";
import { ImageLink } from "./index";

const Feed = ({
  articleEdges,
  pageContext,
}: {
  articleEdges: readonly GatsbyTypes.ArticleEdge[] | undefined;
  pageContext: GatsbyTypes.SitePageContext;
}): JSX.Element | null => {
  const articles = articleEdges?.map(({ node }) => node);
  const article_count = articles?.length || 0;

  const frontmatter = pageContext.frontmatter;
  const hasMoreLinks = frontmatter?.has_more_link;
  const moreLinkText = frontmatter?.more_link_text;

  return (
    <div className="post-feed">
      {article_count > 0 &&
        ((): unknown[] | Array<unknown> | boolean[] => {
          const articles_sorted = orderBy(
            articles,
            "childMarkdownRemark.frontmatter.date",
            "desc"
          );
          return map(articles_sorted, (article, article_idx) => (
            <article key={article_idx} className="post">
              <header className="post-header">
                <h2 className="post-title">
                  {article.externalUrl ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={article.externalUrl}
                    >
                      {article?.childMarkdownRemark?.frontmatter?.title}
                    </a>
                  ) : (
                    <Link
                      to={withPrefix(article?.childMarkdownRemark?.fields?.url)}
                      rel="bookmark"
                    >
                      {article?.childMarkdownRemark?.frontmatter?.title}
                    </Link>
                  )}
                </h2>
                <div className="post-meta">
                  Veröffentlicht am{" "}
                  <time
                    className="published"
                    dateTime={dayjs(
                      article?.childMarkdownRemark?.frontmatter?.date
                    )
                      .locale("de")
                      .format("YYYY-MM-DD HH:mm")}
                  >
                    {dayjs(article?.childMarkdownRemark?.frontmatter?.date)
                      .locale("de")
                      .format("DD. MMMM YYYY")}
                  </time>
                  <br />
                  {map(article.tags, (tag, tag_idx) => {
                    return (
                      <Badge
                        key={tag_idx}
                        label={tag?.title}
                        color={tag?.color}
                        childSlug={buildSlug(tag?.title)}
                      />
                    );
                  })}
                </div>
              </header>
              <ImageLink article={article} articles={articleEdges} />
              <div className="post-content">
                <p>{article.childMarkdownRemark?.frontmatter?.excerpt}</p>
              </div>
              {hasMoreLinks && moreLinkText && (
                <p className="read-more">
                  {article.externalUrl ? (
                    <a
                      className="read-more-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={article.externalUrl}
                    >
                      {moreLinkText} ({"  "}
                      {new URL(article.externalUrl as string).hostname} ){" "}
                      <span className="icon-arrow-right" aria-hidden="true" />
                    </a>
                  ) : (
                    <Link
                      className="read-more-link"
                      to={withPrefix(article.childMarkdownRemark?.fields?.url)}
                    >
                      {moreLinkText}{" "}
                      <span className="icon-arrow-right" aria-hidden="true" />
                    </Link>
                  )}
                </p>
              )}
            </article>
          ));
        })()}
    </div>
  );
};

export default Feed;
