import { graphql, useStaticQuery } from "gatsby";
import { getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import React from "react";
// import Branding from './Branding';
import Navigation from "./Navigation";

const Header = ({
  image,
  site,
  page,
  ...props
}: {
  image: GatsbyTypes.Maybe<GatsbyTypes.File>;
  site: GatsbyTypes.Maybe<GatsbyTypes.SitePageContextSite>;
  page: GatsbyTypes.SitePageContext;
}): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      strapiSiteConfig {
        id
        header {
          background_img {
            id
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  `);
  const bgImage: IGatsbyImageData | undefined = image
    ? getImage(image as unknown as ImageDataLike)
    : getImage(data.strapiSiteConfig.header.background_img.localFile);
  const backgroundImageStack: Array<string | IGatsbyImageData | undefined> = [
    bgImage,
    `linear-gradient(to bottom, rgba(17, 17, 17, 0) 0, rgba(17, 17, 17, 0.01) 1%, rgba(17, 17, 17, 0.7) 70%, rgba(17, 17, 17, 0.9) 100%)`,
  ].reverse();
  return (
    <header id="masthead" className="site-header">
      {bgImage && (
        <BgImage
          className="site-header-bg"
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          image={backgroundImageStack}
          style={{ position: "absolute" }}
        />
      )}
      <div className="site-header-scroll">
        <div className="site-header-inside">
          <div className="site-header-vertical">
            {/* <Branding {...props} site={site} page={page} /> */}
            <Navigation {...props} site={site} page={page} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
