import React from "react";
import { Link, withPrefix } from "../utils";

const TAGS_BASE_PATH = "tags";

const Badge = ({
  label,
  color,
  childSlug,
}: {
  label: string | undefined;
  color: string | undefined;
  childSlug: string | undefined;
}): JSX.Element | null => {
  return childSlug ? (
    <Link
      className="post-tag nohover"
      style={{ backgroundColor: color }}
      to={withPrefix(`/${TAGS_BASE_PATH}${childSlug}`)}
    >
      {label}
    </Link>
  ) : null;
};

export default Badge;
