import { Link } from "gatsby";
import React, { useEffect, useState } from "react";

const ScrollToTopBtn = (): JSX.Element => {
  const [shown, setShown] = useState(false);
  // change state on scroll
  useEffect(() => {
    const rootElement = document.documentElement;
    const handleScroll = (): void => {
      const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
      if (rootElement.scrollTop / scrollTotal > 0.25) {
        // Show button
        setShown(true);
      } else {
        setShown(false);
      }
    };
    document.addEventListener("scroll", handleScroll);

    return (): void => {
      // clean up the event handler when the component unmounts
      document.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <div className={`scrollToTopBtn ${shown ? "showBtn" : ""}`}>
      <Link id="to-top" className="button-circle" to="#page">
        <span className="icon-arrow-up" aria-hidden="true" />
      </Link>
    </div>
  );
};

export default ScrollToTopBtn;
