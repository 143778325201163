import { get, map, trim } from "lodash-es";
import React, { useState } from "react";

import { classNames, Link, withPrefix } from "../utils";
import Icon from "./Icon";

const Navigation = ({
  site,
  page,
}: {
  site: GatsbyTypes.Maybe<GatsbyTypes.SitePageContextSite>;
  page: GatsbyTypes.SitePageContext;
}): JSX.Element | null => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = (): void => {
    setShowMenu(!showMenu);
    document.body.classList.toggle("menu--opened");
  };

  const metadataHeader = site?.siteMetadata?.header;
  const hasNav = metadataHeader?.has_nav;
  const hasSocial = metadataHeader?.has_social;
  const navLinks = metadataHeader?.nav_links;
  const socialLinks = metadataHeader?.social_links;
  const url = page.url;

  return hasNav || hasSocial ? (
    <React.Fragment>
      <nav
        id="main-navigation"
        className="site-navigation"
        aria-label="Main Navigation"
      >
        <div className="site-nav-wrap">
          <div className="site-nav-inside">
            {hasNav && (
              <ul className="menu">
                {map(navLinks, (action, action_idx) => {
                  const pageUrl = trim(url, "/");
                  const actionUrl = trim(get(action, "url", undefined), "/");
                  return (
                    <li
                      key={action_idx}
                      className={classNames("menu-item", {
                        "current-menu-item": pageUrl === actionUrl,
                      })}
                    >
                      <Link
                        to={withPrefix(action?.url)}
                        {...(get(action, "new_window", undefined)
                          ? { target: "_blank" }
                          : undefined)}
                        {...(get(action, "new_window", undefined) ||
                        get(action, "no_follow", undefined)
                          ? {
                              rel:
                                (get(action, "new_window", undefined)
                                  ? "noopener "
                                  : "") +
                                (get(action, "no_follow", undefined)
                                  ? "nofollow"
                                  : ""),
                            }
                          : undefined)}
                      >
                        {get(action, "label", undefined)}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
            {hasSocial && (
              <div className="social-links">
                {map(
                  socialLinks,
                  (action, action_idx) =>
                    action && (
                      <Link
                        key={action_idx}
                        to={withPrefix(get(action, "url", undefined))}
                        {...(get(action, "new_window", undefined)
                          ? { target: "_blank" }
                          : undefined)}
                        {...(get(action, "new_window", undefined) ||
                        get(action, "no_follow", undefined)
                          ? {
                              rel:
                                (get(action, "new_window", undefined)
                                  ? "noopener "
                                  : "") +
                                (get(action, "no_follow", undefined)
                                  ? "nofollow"
                                  : ""),
                            }
                          : undefined)}
                        className={classNames({
                          "button-circle":
                            get(action, "style", undefined) === "icon",
                        })}
                      >
                        {get(action, "style", undefined) === "icon" &&
                        get(action, "icon_class", undefined) ? (
                          <React.Fragment>
                            <Icon icon={action.icon_class} />
                            <span className="screen-reader-text">
                              {get(action, "label", undefined)}
                            </span>
                          </React.Fragment>
                        ) : (
                          get(action, "label", undefined)
                        )}
                      </Link>
                    )
                )}
              </div>
            )}
          </div>
        </div>
      </nav>
      <button onClick={toggleMenu} id="menu-toggle" className="menu-toggle">
        <span className="screen-reader-text">Menu</span>
        <span className="icon-menu" aria-hidden="true" />
      </button>
    </React.Fragment>
  ) : null;
};

export default Navigation;
