import { withPrefix as gatsbyWithPrefix } from "gatsby";
import { startsWith } from "lodash-es";

const withPrefix = (url: string | undefined): string | undefined => {
  if (!url) {
    return url;
  }

  if (
    startsWith(url, "#") ||
    startsWith(url, "http://") ||
    startsWith(url, "https://")
  ) {
    return url;
  }
  return gatsbyWithPrefix(url);
};
export default withPrefix;
