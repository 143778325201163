import { map } from "lodash-es";
import React from "react";

const FormField = ({
  field,
}: {
  key: number;
  field: GatsbyTypes.Maybe<GatsbyTypes.SitePageContextFrontmatterForm_fields>;
}): JSX.Element => {
  return field?.input_type === "checkbox" ? (
    <div className="form-group form-checkbox">
      <input
        type="checkbox"
        id={field.name}
        name={field.name}
        {...(field.is_required ? { required: true } : null)}
      />
      {field.label && <label htmlFor={field.name}>{field.label}</label>}
    </div>
  ) : field?.input_type === "select" ? (
    <div className="form-group">
      {field.label && <label htmlFor={field.name}>{field.label}</label>}
      <div className="form-select-wrap">
        <select
          id={field.name}
          name={field.name}
          {...(field.is_required ? { required: true } : null)}
        >
          {field.default_value && (
            <option value="">{field.default_value}</option>
          )}
          {map(field.options, (option, option_idx) => (
            <option key={option_idx} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  ) : field?.input_type === "textarea" ? (
    <div className="form-group">
      {field.label && <label htmlFor={field.name}>{field.label}</label>}
      <textarea
        name={field.name}
        id={field.name}
        rows={7}
        {...(field.default_value ? { placeholder: field.default_value } : null)}
        {...(field.is_required ? { required: true } : null)}
      />
    </div>
  ) : (
    <div className="form-group">
      {field?.label && <label htmlFor={field?.name}>{field?.label}</label>}
      <input
        type={field?.input_type}
        name={field?.name}
        id={field?.name}
        {...(field?.default_value
          ? { placeholder: field?.default_value }
          : null)}
        {...(field?.is_required ? { required: true } : null)}
      />
    </div>
  );
};
export default FormField;
