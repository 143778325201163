const attribute = (
  name: string,
  value: GatsbyTypes.Maybe<string>,
  condition?: boolean
): { [x: string]: GatsbyTypes.Maybe<string> } | null => {
  if (typeof condition === "undefined") {
    condition = true;
  }
  return condition ? { [name]: value } : null;
};
export default attribute;
